import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

// Redux setup
import { store } from './components/Store'
import { Provider } from 'react-redux'
import { loadData } from './components/DataRetriever'

const rootNode = document.getElementById('root')
const root = ReactDOM.createRoot(rootNode ?? ErrorComponent())

// Initiall loading
loadData(store.dispatch)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

function ErrorComponent (): HTMLElement {
  const node = document.createElement('div')
  node.innerHTML = '<div>Error! DOM object not found!</div>'
  return node
}
