import {
  type IImageProps,
  type ITextStyles,
  Stack,
  Text,
  Image,
  Link
} from '@fluentui/react'
import { type CSSProperties } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
import './App.css'
import { BasicList } from './components/BasicList'
import { type IHighScoreData } from './components/DataRetriever'
import { DownloadTable } from './components/DownloadTable'
import { type RootState } from './components/Store'

function App (): JSX.Element {
  const highScoreData: IHighScoreData[] = useSelector(
    (state: RootState) => state.highScoreData
  )

  const textStyle: ITextStyles = {
    root: { textAlign: 'left', fontFamily: 'Helvetica', fontSize: 'x-large' }
  }

  const spanStyle: CSSProperties =
  {
    height: '40px'
  }

  const imagePropsScreen: Partial<IImageProps> = {
    src: '../assets/screenshot.webp',
    // Show a border around the image (just for demonstration purposes)
    styles: (props) => ({
      root: {
        border: '0px solid ' + props.theme.palette.neutralSecondary
      },
      shouldFadeIn: true
    })
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>PixelWar - Website</h1>
      </header>
      <body>
        <Stack horizontal={false} verticalFill={true}>
          <h1>PixelWars Overview</h1>
          <Text block={true} styles={textStyle}>
            PixelWars is a small explorative project for me to get used to the
            Unreal engine. It is a classic top-down space shooter where you as a
            defender of earth stand against the endless waves of pixelated
            enemies that intend to invade our solar system and conquer earth.You
            have been proclaimed as a pixel defender of earth and save our
            planet. Therefore get into your pixel spaceship and begin defending
            by downloading for either Windows or Linux using the links below
          </Text>
          <Stack horizontal={true} horizontalAlign="center">
            <Image {...imagePropsScreen} width="300px"></Image>
          </Stack>
          <span style={spanStyle}></span>
          <DownloadTable></DownloadTable>
          <h1>Heroes of the Pixels</h1>
          <BasicList
            items={highScoreData}
            headline={['Name', 'Highscore']}
          ></BasicList>
          <Text>
            Linux svg icon used as licensed by Garuda Technology in their
            <Link href="https://github.com/garudatechnologydevelopers/Sketch-icons/blob/main/LICENSE">
              Sketch-icons
            </Link>
            project
          </Text>
          <Text>
            Windows svg icon used as licensed on
            <Link href="https://www.svgrepo.com/svg/303223/microsoft-windows-22-logo">
               svgrepo
            </Link>
          </Text>
        </Stack>
      </body>
    </div>
  )
}

export default App

/* <Stack horizontal={true} styles={downloadStackStyle}>
            <Text>Windows:</Text>
            <Link href="https://pixelwars.silveroin-gamedev.net/download/pixelwars.exe">
              Join the challenge now!
            </Link>
            <Text>Linux:</Text>
            <Link href="https://pixelwars.silveroin-gamedev.net/download/pixelwars.tar.gz">
              Join the challenge now!
            </Link>
          </Stack> */
