import {
  type IImageProps,
  Image,
  type IStackProps,
  Stack,
  Separator,
  createTheme,
  type ITheme
} from '@fluentui/react'
import React from 'react'

const imagePropsWin: Partial<IImageProps> = {
  src: '../assets/win-original.svg',
  // Show a border around the image (just for demonstration purposes)
  styles: (props) => ({
    root: {
      border: '0px solid ' + props.theme.palette.neutralSecondary
    }
  })
}

const imagePropsLinux: Partial<IImageProps> = {
  src: '../assets/linux.svg',
  // Show a border around the image (just for demonstration purposes)
  styles: (props) => ({
    root: {
      border: '0px solid ' + props.theme.palette.neutralSecondary
    },
    maximizeFrame: true,
    shouldFadeIn: true
  })
}

const downloadStackStyle: IStackProps = {
  horizontalAlign: 'space-evenly',
  gap: 50
}

const theme: ITheme = createTheme({
  fonts: {
    medium: {
      fontFamily: 'Monaco, Menlo, Consolas',
      fontSize: '30px'
    }
  }
})

export const DownloadTable: React.FunctionComponent = () => {
  return (
    <div>
      <Separator theme={theme}>Download</Separator>
      <Stack
        horizontal={true}
        styles={downloadStackStyle}
        grow={1}
        horizontalAlign="center"
      >
        <Image {...imagePropsWin} width="100px"></Image>

        <Image width="200px"></Image>

        <Image {...imagePropsLinux} width="100px"></Image>
      </Stack>
      <Separator></Separator>
    </div>
  )
}
