import { createAction, createSlice } from '@reduxjs/toolkit'
import { type IHighScoreData } from './DataRetriever'

const emptyState: IHighScoreData[] = []

export const updateHighScore = createAction<IHighScoreData[]>(
  'updateHighScore'
)
export const clearHighScore = createAction<IHighScoreData[]>('clearHighScore')

export const highScoreSlice = createSlice({
  name: 'highScoreData',
  initialState: emptyState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateHighScore, (state, action) => {
      state.splice(0)
      const highScoreList = action.payload
      const sortedHighScore = highScoreList.sort((a, b) => {
        return a.score > b.score ? -1 : 1
      })
      state.push(...sortedHighScore)
    })
    builder.addCase(clearHighScore, (state) => {
      state.splice(0)
    })
  }
})

export default highScoreSlice.reducer
