import { List } from '@fluentui/react'
import React, { type CSSProperties } from 'react'
import { BasicListEntry } from './BasicListEntry'
import { type IHighScoreData } from './DataRetriever'

const headlineDivStyle: CSSProperties = {
  width: '100%',
  display: 'table',
  tableLayout: 'fixed'
}

const NameStyle: CSSProperties = {
  textAlign: 'center',
  fontSize: 'medium',
  display: 'table-cell',
  fontWeight: 'bold'
}

export const BasicList: React.FunctionComponent<{
  items: IHighScoreData[]
  headline: [string, string]
}> = ({ items, headline }) => {
  const onRenderCell = React.useCallback(
    (item?: IHighScoreData, index?: number, isScrolling?: boolean) => {
      const rowData = { playerName: '', highscore: 0 }

      if (typeof item === 'undefined') {
        return <></>
      }

      rowData.playerName = item.name
      rowData.highscore = item.score

      return (
        <div data-is-focusable>
          <div>
            <div>
              <span>
                <BasicListEntry
                  highscore={rowData.highscore}
                  playerName={rowData.playerName}
                ></BasicListEntry>
              </span>
            </div>
          </div>
        </div>
      )
    },
    []
  )

  return (
    <div>
      <div style={headlineDivStyle}>
        <span style={NameStyle}>{headline[0]}</span>
        <span style={NameStyle}>{headline[1]}</span>
      </div>
      <hr></hr>
      <List items={items} onRenderCell={onRenderCell}></List>
    </div>
  )
}
