import {
  getFocusStyle,
  getTheme,
  type ITheme,
  mergeStyleSets
} from '@fluentui/react'
import React from 'react'

interface IBasicListEntry {
  playerName: string
  highscore: number
}

const theme: ITheme = getTheme()
const { palette, semanticColors, fonts } = theme

const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      width: '100%',
      minHeight: 54,
      padding: 10,
      boxSizing: 'border-box',
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: 'table',
      tableLayout: 'fixed',
      selectors: {
        '&:hover': { background: palette.neutralLight }
      }
    }
  ],
  itemContent: [
    fonts.xLarge,
    {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1,
      textAlign: 'center',
      display: 'table-cell'
    }
  ],
  highscoreItem: [
    fonts.xLarge,
    {
      textAlign: 'center',
      display: 'table-cell'
    }
  ],
  itemSpacer: {
    width: '150px'
  }
})

export const BasicListEntry = (entry: IBasicListEntry): JSX.Element => {
  return (
    <div className={classNames.itemCell}>
      <span className={classNames.itemContent}>{entry.playerName}</span>
      <span className={classNames.highscoreItem}>{entry.highscore}</span>
    </div>
  )
}
