import axios from 'axios'
import { updateHighScore } from './DataRetrieverSlice'
import { type AppDispatch } from './Store'

// const localServer = "http://localhost:12010/v1/highscore";
const prodServer = 'https://silvercoin-gamedev.net/v1/highscore'

export interface IHighScoreData {
  id: string
  name: string
  score: number
}

export function loadData (dispatch: AppDispatch): void {
  axios
    .get(prodServer, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      const data: IHighScoreData[] = response.data
      if (data.length !== 0) {
        dispatch(updateHighScore(data))
      }
    })
    .catch((ex) => {
      const error =
        ex.response.status !== 200
          ? 'Error during REST API call: ' + (+ex.response.status).toString()
          : 'REST API call successful!'
      console.log('REST API call result: ' + error)
    })
}
